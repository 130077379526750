<template>
  <div
    :class="['pdp-sticky-cta-shipping', { 'is-sticky': isSticky }]"
  >
    <div v-if="isShowDeliveryStore" class="shipping-item">
      <div class="shipping-item-icon">
        <img
          class="img"
          src="@/assets/icon/studio7/store-black.svg"
          alt="store"
        />
      </div>

      <div class="shipping-item-type">
        <div class="title-medium">
          รับสินค้าที่ร้าน
        </div>
        <div :class="['body-s remark', isHighlight]">
          <!-- เริ่มรับสินค้าวันที่  -->
          {{ shipDate }}
        </div>
      </div>
    </div>

    <div v-if="isShowDeliveryHome" class="shipping-item">
      <div class="shipping-item-icon">
        <img
          class="img"
          src="@/assets/icon/studio7/shipping-black.svg"
          alt="store"
        />
      </div>

      <div class="shipping-item-type">
        <div class="title-medium">
          การจัดส่ง
        </div>
        <!-- <div v-if="!isSticky" class="body-s-semibold">
          การจัดส่งแบบปกติ
        </div> -->
        <div v-if="!isSticky" :class="['body-s remark', isHighlight]">
          <!-- สินค้าเริ่มจัดส่งวันที่  -->
          {{ shipDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SHIPPING_DATE } from '@/config/index'
import moment from 'moment'

export default {
  name: 'PDPStickyCTAShipping',

  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      campaign: state => state.campaign.current,
    }),

    shipDate() {
      return this.selectSKU?.active
        ? this.selectSKU?.shipping_date ||
            this.campaign?.data?.shipdate ||
            'สินค้าไม่พร้อมบริการ'
        : 'สินค้าไม่พร้อมบริการ'
    },

    isHighlight() {
      let highlight =
        this.selectSKU?.actual_date &&
        moment(this.selectSKU?.actual_date).isAfter(
          moment(SHIPPING_DATE.FIRST_LOT),
        )

      let className =
        this.selectSKU?.actual_date === SHIPPING_DATE.SECOND_LOT
          ? 'ship-date-highlight lot-2'
          : this.selectSKU?.actual_date === SHIPPING_DATE.THIRD_LOT
          ? 'ship-date-highlight lot-3'
          : ''

      return highlight ? className : ''
    },

    isShowDeliveryHome() {
      return this.campaign?.data?.is_delivery_home ?? false
    },

    isShowDeliveryStore() {
      return this.campaign?.data?.is_delivery_store ?? false
    },
  },
}
</script>

<style lang="stylus" scoped>
.pdp-sticky-cta-shipping {
    .shipping-item {
        display: flex;
        gap: 8px;
        margin-top: 12px;



        &:first-child {
            margin-top: 0;
        }

        .shipping-item-icon {
            width: 16px;
            height: 16px;

            .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &.is-sticky .shipping-item {
        margin-top: 8px;
    }
}

.remark {
    color: $color-gray-2;
}
</style>
